import { Link } from "gatsby"
import React, { useRef } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"

import PikselFs from "../../../components/fullscreen/PikselFs"
import Seo from "../../../components/Seo"
import OluPikselGaleri from "../../../contents/Gallery/OluPikselGaleri"
import SvgLeftIcon from "../../../contents/Icons/SvgLeftIcon"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
import SvgPixelTest from "../../../contents/SVG/SvgPixelTest"
import EndIndicator from "../components/EndIndicator"
import StartIndicator from "../components/StartIndicator"
const DeadPixelTest = () => {
  const refPikselFs = useRef()

  return (
    <>
      <Seo
        lang="de"
        keywords="Test für tote Pixel, Test für festsitzende Pixel, Reparatur für festsitzende Pixel, Reparatur für festsitzende Pixel, Test für festsitzende Pixel, was ist ein toter Pixel, was ist ein festsitzender Pixel, was ist ein festsitzender Pixel?, totes Pixel, totes Pixel, Pixeltest, steckengebliebenes Pixel, Pixelreparatur online"
        title="Dead and Stuck Pixel Test"
        description="Testen Sie tote Pixel und steckende Pixel auf Ihrem Android, iPhone, Tablet, PC, Smart-TV, Monitor und Computerbildschirm auf der kostenlosen Website ohne Installation."
      />
      <h1 className="text-center md:text-left">
        Dead Pixel oder Stuck Pixel Test
      </h1>
      <p className="lead">
        Sie können <strong>tote Pixel</strong> oder{" "}
        <span className="font-bold text-purple-600">festsitzende Pixel</span>{" "}
        auf Ihrem Bildschirm erkennen, indem Sie ohne Setup auf die Schaltfläche{" "}
        <code>Test Dead - Stuck Pixels</code> unten klicken.
      </p>

      <div
        className="px-4 py-3 text-blue-900 bg-blue-100 border-t-4 border-blue-500 rounded-b shadow-md"
        role="alert"
      >
        <p className="font-bold">
          Bevor Sie mit dem Dead-Stuck-Pixel-Test beginnen, sollten Sie
          Folgendes berücksichtigen:
        </p>
        <p className="">
          Stellen Sie vor dem Totpixeltest sicher, dass Ihr Bildschirm sauber
          und Ihre Umgebung nicht zu hell ist. Während der Staub auf Ihrem
          Bildschirm Sie irreführen kann, kann eine übermäßige Helligkeit dazu
          führen, dass Sie tote Pixel übersehen.
        </p>
      </div>

      <div className="mt-10 text-center">
        <PurpleSolidButton
          text="Test Dead - Stuck Pixels"
          onClick={() => refPikselFs.current.open()}
        />
      </div>
      <h2>Wie kann ich tote oder steckende Pixel testen?</h2>
      <p>
        Nachdem Sie oben auf die Schaltfläche{" "}
        <code>Test Dead - Stuck Pixels</code> geklickt haben, wird die Site im
        Vollbildmodus angezeigt. Informationen sind auf dem sich öffnenden
        Bildschirm verfügbar.
      </p>
      <p>
        Um <strong>tote Pixel</strong> oder{" "}
        <span className="font-bold text-purple-600">festsitzende Pixel</span> zu
        erkennen, müssen Sie die Farben Rot, Grün, Blau, Schwarz und Weiß, die
        den gesamten Bildschirm belegen, einzeln überprüfen.
      </p>

      <SvgPixelTest className="max-w-full px-3 md:px-10" />
      <p>
        Um im Vollbildmodus zwischen den Farben zu wechseln, können Sie mit der
        linken Maustaste auf eine beliebige Stelle auf dem Bildschirm klicken
        oder die Tasten{" "}
        <SvgLeftIcon className="inline text-purple-700 fill-current" /> und{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" /> (links
        und rechts) Ihrer Tastatur drücken.
      </p>
      <blockquote>
        Bevor Sie mit dem Test auf tote Pixel beginnen, beachten Sie bitte die
        Warnung über dem blauen Informationsfeld oben.
      </blockquote>
      <p>
        Wenn Sie einen Punkt oder Punkte mit einer anderen Farbe als der
        Hintergrundfarbe auf Ihrem Bildschirm erkennen, bedeutet dies, dass Sie
        ein <strong>totes Pixel</strong> oder ein{" "}
        <span className="font-bold text-purple-600">feststeckendes Pixel</span>{" "}
        auf Ihrem Bildschirm haben.
      </p>

      <blockquote>
        Sie können{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        drücken, um den Test zu beenden.
      </blockquote>
      <p>
        Sie können Beispielbilder von toten oder stecken gebliebenen Pixeln
        anzeigen, indem Sie unten auf die Schaltfläche{" "}
        <code>Dead - Stuck Pixel Samples</code> klicken.
      </p>
      <OluPikselGaleri text="Dead - Stuck Pixel Samples" />
      <h2>Was ist Dead Pixel?</h2>
      <p>
        <stong>Dead Pixel</stong> ist ein Pixel, das auf Ihrem Bildschirm nicht
        funktioniert, weil Sie keine Energie erhalten. Aus diesem Grund haben{" "}
        <strong>tote Pixel</strong> normalerweise eine schwarze Farbe.
      </p>
      <p>
        Obwohl <strong>tote Pixel</strong> meistens schwarz sind, können sie auf
        einigen Displays weiß sein.
      </p>
      <p>
        Weitere Informationen zur Reparatur toter Pixel und zur Reparatur toter
        Pixel erhalten Sie auf der entsprechenden Seite, indem Sie unten auf die
        Schaltfläche <code>Reparatur toter Pixel</code> klicken.
      </p>

      <div className="my-3 text-center">
        <Link
          to="/dead-pixel-fix-de"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Reparatur toter Pixel</span>
        </Link>
      </div>

      <h2>Was ist Stuck Pixel?</h2>
      <p className="lead">
        Im Gegensatz zu <strong>toten Pixeln</strong> erhalten festsitzende
        Pixel immer noch Energie. Aus diesem Grund ist die Reparatur
        festsitzender Pixel viel einfacher.
      </p>
      <p>
        <span className="font-bold text-purple-600">Festgeklebte Pixel</span>{" "}
        sind Pixel, die meist in Grundfarben wie{" "}
        <span className="text-red-600">Rot</span>,{" "}
        <span className="text-green-400">Grün</span>,{" "}
        <span className="text-blue-600">Blau</span> verbleiben, z. B. um lange
        Zeit dieselbe Farbe zu behalten und kontinuierlich dieselbe Farbe
        wiederzugeben.
      </p>
      <p>
        Weitere Informationen zum Feststecken von Pixeln und zum Reparieren
        festsitzender Pixel erhalten Sie auf der entsprechenden Seite, indem Sie
        unten auf die Schaltfläche <code>Feststeckende Pixel reparieren</code>{" "}
        klicken.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/stuck-pixel-fix-de"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Feststeckende Pixel reparieren</span>
        </Link>
      </div>
      <PikselFs
        ref={refPikselFs}
        startIndicator={<StartIndicator />}
        endIndicator={
          <EndIndicator
            closeTest={() => refPikselFs.current.close()}
            resetTest={() => refPikselFs.current.reset()}
          />
        }
      />
    </>
  )
}
export default DeadPixelTest
