import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
import RedSolidButton from "../../../components/Buttons/RedSolidButton"
const EndIndicator = ({ closeTest, resetTest }) => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        <span role="img" aria-label="Herzliche Glückwünsche">
          🎉
        </span>
        Sie haben den Totpixeltest erfolgreich abgeschlossen
        <span role="img" aria-label="Herzliche Glückwünsche">
          🎉
        </span>
      </p>
      <p className="lead">
        Ich hoffe, es gibt keine toten Pixel auf Ihrem Bildschirm.
      </p>
      <p className="lead">
        Sie können{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        drücken, um den Test zu beenden.
      </p>
      <div>
        <RedSolidButton
          text="Ausgang"
          onClick={closeTest}
          style={{ marginRight: "8px" }}
        />
        <PurpleSolidButton text="Erneut Testen" onClick={resetTest} />
      </div>
    </div>
  )
}
export default memo(EndIndicator)
